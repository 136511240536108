
import { computed, defineComponent, ref } from 'vue';
import TopMenu from '@/components/TopMenu.vue';

import { configureOidc } from './services/auth.service';
import { updateAbility } from '@/plugins/AbilityPlugin';
import { appState } from '@/store';

export default defineComponent({
  components: {
    TopMenu
  },
  setup() {
    configureOidc().then((authService) => {
      updateAbility(authService.userProfile);
    });
    
    appState.updateShowEnvironmentBanner();
    const errors = ref<Array<string>>();
    const showEnvironmentBanner = computed<boolean>(() => {
      return appState.showBanner;
    });
    const environmentName = computed<string>(() => {
      return appState.environmentName;
    });
    appState.$watch(
      (module) => module.errors,
      (systemErrors: string[] | string) => {
        errors.value = Array.isArray(systemErrors)
          ? systemErrors
          : [systemErrors];
        errors.value.forEach((error) => {
          console.error(error);
        });
      }
    );
    return { errors, environmentName, showEnvironmentBanner };
  }
});
