import { RouteRecordRaw } from 'vue-router';
import template from '@/views/defaultTemplate.vue';
import { OidcAuth } from 'vue-oidc-client/vue3';
export default function useErrorCorrectionRoutes(
  idsrvAuth: OidcAuth
): Array<RouteRecordRaw> {
  const routes: Array<RouteRecordRaw> = [
    {
      path: '/errorCorrection',
      name: 'errorCorrection',
      component: template,
      meta: {
        //        authName: idsrvAuth.authName
      },
      children: [
        {
          path: '',
          name: 'errorCorrection_error',
          component: () =>
            import(
              /* webpackChunkName: "errorCorrection" */ '@/views/ErrorCorrection/error.vue'
            )
        },
        {
          path: ':id',
          name: 'errorCorrection_start',
          component: () =>
            import(
              /* webpackChunkName: "errorCorrection" */ '@/views/ErrorCorrection/main.vue'
            )
        },
        {
          path: ':id/login',
          name: 'errorCorrection_login',
          component: () =>
            import(
              /* webpackChunkName: "errorCorrection" */ '@/views/ErrorCorrection/loadErrorCorrection.vue'
            ),
          meta: {
            authName: idsrvAuth.authName
          }
        }
      ]
    }
  ];

  return routes;
}
