import { User, UserManagerSettings } from 'oidc-client';
import {
  createOidcAuth,
  SignInType,
  LogLevel,
  OidcAuth
} from 'vue-oidc-client/vue3';
import { userModule } from '@/store';
const loco = window.location;
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;
import { updateAbility } from '@/plugins/AbilityPlugin';

let authObj = null as OidcAuth | null;
export async function configureOidc(): Promise<OidcAuth> {
  if (authObj) return Promise.resolve(authObj);

  const configFetch = await fetch('/_configuration/PortalVueApp');
  const config: UserManagerSettings = await configFetch.json();
  authObj = createOidcAuth(
    'main',
    SignInType.Window,
    appRootUrl,
    {
      silent_redirect_uri: `${appRootUrl}authentication/signinsilent`,
      ...config,
      prompt: 'login'
    },
    console,
    LogLevel.Debug
  );

  // handle events
  authObj.events.addAccessTokenExpiring(function () {
    if (authObj) {
      authObj.startSilentRenew();
    }
  });

  authObj.events.addAccessTokenExpired(function () {
    if (authObj) {
      authObj.signIn();
    }
    window.location.href = appRootUrl;
  });

  authObj.events.addSilentRenewError(function (err: Error) {
    // eslint-disable-next-line no-console
    console.error('silent renew error', err);
  });

  authObj.events.addUserLoaded(function (user: User) {
    updateAbility(user.profile);

    userModule.setFirstName(user.profile.given_name ?? '');
    userModule.setFirstName(user.profile.family_name ?? '');
  });

  authObj.events.addUserUnloaded(function () {
    if (authObj) {
      authObj.signIn();
    }
    window.location.href = appRootUrl;
  });

  authObj.events.addUserSignedOut(function () {
    if (authObj) {
      authObj.signIn();
    }
    window.location.href = appRootUrl;
  });

  authObj.events.addUserSessionChanged(function () {
    // eslint-disable-next-line no-console
    console.log('user session changed');
  });

  return authObj;
}
