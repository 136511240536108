import { createApp } from 'vue';
import App from './App.vue';
import { configureRouter } from './router';
import store from './store';
import { configureOidc } from './services/auth.service';
import useVeeValidate from '@/plugins/useVeeValidate';
import { ability } from '@/plugins/AbilityPlugin';
import { abilitiesPlugin, Can } from '@casl/vue';
import 'bootstrap';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

void (async function () {
  const idsrvAuth = await configureOidc();
  const router = await configureRouter();

  idsrvAuth.startup().then((ok: boolean) => {
    if (ok) {
      library.add(faQuestionCircle)

      const app = createApp(App)
        .use(store)
        .use(router)
        .use(abilitiesPlugin, ability)
        .component('font-awesome-icon', FontAwesomeIcon)
        .component(Can.name, Can); // component registration
      // a little something extra
      app.config.globalProperties.$oidc = idsrvAuth;
      app.mount('#app');
      useVeeValidate();
    } else {
      console.log('Startup was not ok');
    }
  });
})();
