import { VuexModule, Module, Action } from 'vuex-class-modules';
@Module({ generateMutationSetters: true })
export default class ErrorCorrectionModule extends VuexModule {
  // state
  name: string | null = null;
  email: string | null = null;

  @Action
  setName(payload: string): void {
    this.name = payload;
  }
  @Action
  setEmail(payload: string | null): void {
    this.email = payload;
  }
}
