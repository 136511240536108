export default interface IPagedCommandResult<T> {
  data: T;
  success: boolean;
  errors: Array<string>;
  size: number;
  page: number;
  total: number;
}

export class PagedModel {
  size: number = 100;
  page: number = 1;
  total: number = 0;

  pages = (): number => {
    if (this.total === 0) return 0;
    return Math.round(this.total / this.size);
  };
}
