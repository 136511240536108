import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { useAbility } from '@casl/vue';
import { Profile } from 'oidc-client';

export type Actions = 'create' | 'read' | 'update' | 'delete' | 'manage';
export type Subjects = 'Company' | 'User' | 'all';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
export const ability = new AppAbility();
export const useAppAbility = (): AppAbility => useAbility<AppAbility>();

export const updateAbility = (profile: Profile): void => {
  if (profile) {
    const { can, rules } = new AbilityBuilder(AppAbility);
    if (profile.role === 'Admin') {
      can('read', 'User');
      can('update', 'User');
      can('delete', 'User');
      can('read', 'Company');
      can('update', 'Company');
      can('delete', 'Company');
    }
    ability.update(rules);
  }
};
