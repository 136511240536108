import { VuexModule, Module, Mutation } from 'vuex-class-modules';

@Module({ generateMutationSetters: true })
export default class UserModule extends VuexModule {
  // state
  firstName = '';
  lastName = '';
  email = '';

  // getters
  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }
  // mutations
  @Mutation
  setFirstName(firstName: string): void {
    this.firstName = firstName;
  }
  @Mutation
  setLastName(lastName: string): void {
    this.lastName = lastName;
  }
  @Mutation
  setEmail(email: string): void {
    this.email = email;
  }
}
