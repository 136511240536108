import { VuexModule, Module, Action } from 'vuex-class-modules';
import CustomerViewModel from '@/Model/CustomerViewModel';
import InspectionViewModel from '@/Model/InspectionViewModel';

@Module({ generateMutationSetters: true })
export default class CustomerModule extends VuexModule {
  // state
  customer: CustomerViewModel | null = null;
  inspection: InspectionViewModel | null = null;

  @Action
  setCustomer(payload: CustomerViewModel): void {
    this.customer = payload;
    this.inspection = null;
  }
  @Action
  setInspection(payload: InspectionViewModel | null): void {
    this.inspection = payload;
  }
}
