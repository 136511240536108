
import { getCurrentInstance, defineComponent, computed, watch } from 'vue';
import { OidcAuth } from 'vue-oidc-client/vue3';
import { configureOidc } from '@/services/auth.service';
import { appState } from '@/store';
export default defineComponent({
  setup() {
    const oidc: OidcAuth =
      getCurrentInstance()?.appContext.config.globalProperties.$oidc;
    const userName = computed<string>(() => {
      let name = '';
      if (oidc.userProfile.given_name) {
        name = oidc.userProfile.given_name;
      }
      if (oidc.userProfile.family_name) {
        name = `${name} ${oidc.userProfile.family_name}`;
      }
      return name;
    });
    const isAuthenticated = computed<boolean>(() => {
      return oidc.isAuthenticated;
    });
    watch(
      () => isAuthenticated.value,
      (isAuthenticated: boolean) => {
        if (isAuthenticated) {
          appState.updateMessageCount();
        }
      }
    );
    const messageCount = computed<number>(() => {
      return appState.messageCount;
    });
    const signOut = () => {
      configureOidc().then((oidc: OidcAuth) => {
        oidc.signOut().then(() => {
          window.location.href = '/Identity/Account/Logout';
        });
      });
    };

    return {
      userName,
      signOut,
      messageCount
    };
  }
});
