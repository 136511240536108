<template>
    <div v-if="showEnvironmentBanner" style="display: flex; justify-content: center">
      <div style="
            background-color: yellow;
            text-align: center;
            padding: 10px;
            font-weight: bold;
            margin: 0 auto;
            position: fixed;
            z-index: 2000;
            ">
        THIS IS THE {{ environmentName }} ENVIRONMENT
      </div>
  </div>
  <div>
    <TopMenu></TopMenu>
    <div class="container">
        <router-view />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import TopMenu from '@/components/TopMenu.vue';

import { configureOidc } from './services/auth.service';
import { updateAbility } from '@/plugins/AbilityPlugin';
import { appState } from '@/store';

export default defineComponent({
  components: {
    TopMenu
  },
  setup() {
    configureOidc().then((authService) => {
      updateAbility(authService.userProfile);
    });
    
    appState.updateShowEnvironmentBanner();
    const errors = ref<Array<string>>();
    const showEnvironmentBanner = computed<boolean>(() => {
      return appState.showBanner;
    });
    const environmentName = computed<string>(() => {
      return appState.environmentName;
    });
    appState.$watch(
      (module) => module.errors,
      (systemErrors: string[] | string) => {
        errors.value = Array.isArray(systemErrors)
          ? systemErrors
          : [systemErrors];
        errors.value.forEach((error) => {
          console.error(error);
        });
      }
    );
    return { errors, environmentName, showEnvironmentBanner };
  }
});
</script>

<style lang="scss">
@import '@/style.scss';
</style>
