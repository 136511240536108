import { required, email, confirmed } from '@vee-validate/rules';
import { defineRule } from 'vee-validate';
// import { localize } from '@vee-validate/i18n';
// import da from '@vee-validate/i18n/dist/locale/da.json';
// import en from '@vee-validate/i18n/dist/locale/en.json';
// import { setLocale } from 'yup';
export default function (): void {
  defineRule('required', required);
  defineRule('email', email);
  defineRule('confirmed', confirmed);

  // configure({
  //   // Generates an English message locale generator
  //   generateMessage:localize({
  //     en,
  //     da,
  //   }),
  // });
}
