import { RouteRecordRaw } from 'vue-router';
import { OidcAuth } from 'vue-oidc-client/vue3';

export default function useAccountRoutes(
  idsrvAuth: OidcAuth
): Array<RouteRecordRaw> {
  const routes: Array<RouteRecordRaw> = [
    {
      path: '/customer',
      name: 'customer',
      // redirect: { name: 'customers' },
      component: () =>
        import(
          /* webpackChunkName: "customer" */ '@/views/components/customer.vue'
        ),
      meta: {
        authName: idsrvAuth.authName
      },
      children: [
        {
          path: ':id',
          name: 'inspection',
          component: () =>
            import(
              /* webpackChunkName: "customer" */ '@/views/components/ListInspection.vue'
            ),
          children: []
        },
        {
          path: ':id/:inspection',
          name: 'inspection_documents',
          meta: {
            authName: idsrvAuth.authName
          },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(
              /* webpackChunkName: "customer" */ '@/views/components/Inspection.vue'
            )
        },
        {
          path: ':id/:inspection/error',
          name: 'inspection_error',
          meta: {
            authName: idsrvAuth.authName
          },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(
              /* webpackChunkName: "customer" */ '@/views/components/InspectionError.vue'
            )
        }
      ]
    }
  ];

  return routes;
}
