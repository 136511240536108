<template>
  <nav class="navbar navbar-expand-md" id="topNavBar">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img class="logo" src="../assets/images/logo_white.png" height="30" />
      </a>
      <div class="navbar-collapse collapse" v-if="$oidc.isAuthenticated">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link :to="{ path: '/' }" class="nav-link">
              Forside
            </router-link>
          </li>
          <Can I="read" a="User">
            <li class="nav-item">
              <router-link :to="{ name: 'users' }" class="nav-link">
                Brugere
              </router-link>
            </li>
          </Can>
          <Can I="read" a="Company">
            <li class="nav-item">
              <router-link :to="{ name: 'customers' }" class="nav-link">
                Kunder
              </router-link>
            </li>
          </Can>
        </ul>
      </div>

      <ul class="navbar-nav" v-if="$oidc.isAuthenticated">
        <li class="nav-item">
          <span
            v-if="messageCount > 0"
            class="badge rounded-pill bg-secondary"
            style="float: right; margin-bottom: -10px"
          >
            {{ messageCount }}
          </span>
          <router-link :to="{ name: 'Notification' }" class="nav-link">
            Beskeder
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/identity/account/Manage">
            {{ userName }}
          </a>
        </li>
        <li class="nav-item">
          <a @click="signOut" class="nav-link btn btn-link">
            <i class="bi bi-box-arrow-in-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script lang="ts">
import { getCurrentInstance, defineComponent, computed, watch } from 'vue';
import { OidcAuth } from 'vue-oidc-client/vue3';
import { configureOidc } from '@/services/auth.service';
import { appState } from '@/store';
export default defineComponent({
  setup() {
    const oidc: OidcAuth =
      getCurrentInstance()?.appContext.config.globalProperties.$oidc;
    const userName = computed<string>(() => {
      let name = '';
      if (oidc.userProfile.given_name) {
        name = oidc.userProfile.given_name;
      }
      if (oidc.userProfile.family_name) {
        name = `${name} ${oidc.userProfile.family_name}`;
      }
      return name;
    });
    const isAuthenticated = computed<boolean>(() => {
      return oidc.isAuthenticated;
    });
    watch(
      () => isAuthenticated.value,
      (isAuthenticated: boolean) => {
        if (isAuthenticated) {
          appState.updateMessageCount();
        }
      }
    );
    const messageCount = computed<number>(() => {
      return appState.messageCount;
    });
    const signOut = () => {
      configureOidc().then((oidc: OidcAuth) => {
        oidc.signOut().then(() => {
          window.location.href = '/Identity/Account/Logout';
        });
      });
    };

    return {
      userName,
      signOut,
      messageCount
    };
  }
});
</script>
<style lang="scss" scoped>
@import '../style.scss';
#topNavBar {
  background: $primary;
  border-bottom-color: $orange;
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.nav-link {
  color: #fff;
}
</style>
