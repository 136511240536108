import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  Router
} from 'vue-router';
import { configureOidc } from '@/services/auth.service';
import { appState } from '@/store';

//import useAccountRoutes from './accountRoutes';
import useAdminRoutes from './adminRoutes';
import useCustomerRoutes from './customerRoutes';
import useErrorCorrectionRoutes from './errorCorrectionRoutes';

import { ability, Actions, Subjects } from '@/plugins/AbilityPlugin';
let routerObj = null as Router | null;

export async function configureRouter(): Promise<Router> {
  if (routerObj) return Promise.resolve(routerObj);
  const idsrvAuth = await configureOidc();
  const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'Home',
      component: () =>
        import(/* webpackChunkName: "customer" */ '@/views/Home.vue'),
      meta: {
        authName: idsrvAuth.authName,
        title: 'Portal'
      }
    },
    {
      path: '/Notification',
      name: 'Notification',
      meta: {
        authName: idsrvAuth.authName,
        title: 'Beskeder'
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "Notification" */ '@/views/Notifications.vue'
        )
    },
    //...useAccountRoutes(idsrvAuth),
    ...useCustomerRoutes(idsrvAuth),
    ...useAdminRoutes(idsrvAuth),
    ...useErrorCorrectionRoutes(idsrvAuth)
  ];

  routerObj = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  routerObj.beforeEach((to, from, next) => {
    const canNavigate = to.matched.some((route) => {
      const action: Actions = (route.meta.action as Actions) || 'read';
      const subject: Subjects = route.meta.resource as Subjects;
      if (subject) {
        return ability.can(action, subject);
      }
      return true;
    });
    const title: string = to.meta.title as string;
    if (title) {
      appState.setTitle(title);
    }
    if (!canNavigate) {
      return next('/');
    } else if (
      to.name === 'errorCorrection_login' &&
      from.name === 'signinwin-main'
    ) {
      return next(to.fullPath.replace('/login', ''));
    }
    next();
  });
  idsrvAuth.useRouter(routerObj);
  return routerObj;
}

// const routes: Array<RouteConfig> = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home,
//   },
//   {
//     path: '/about',
//     name: 'About',
//     meta: {
//       authName: idsrvAuth.authName,
//     },
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ './views/About.vue'),
//   },
// ];
