import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import useNotificationForUser from '@/store/useNotificationForUser';

@Module
export default class AppModule extends VuexModule {
  // state
  title = 'Portal';
  messageCount = 0;
  errors: Array<string> = [];
  success = '';
  showBanner = false;
  environmentName = '';
  @Action
  setTitle(payload: string): void {
    this.changeTitle(payload);
    document.title = `CRM - ${this.title}`;
  }
  @Action
  updateMessageCount(): void {
    const { getUnread } = useNotificationForUser();
    getUnread()
      .then((result: number) => {
        this.setMessageCount(result);
      })
      .catch(this.raiseError);
  }
  @Action
  updateShowEnvironmentBanner(): void {
    const { getEnvironmentBanner } = useNotificationForUser();
    getEnvironmentBanner()
      .then((result: { showBanner: boolean; message: string }) => {
        this.setShowBanner( result );
      })
      .catch(this.raiseError);
  }
  @Mutation
  setShowBanner(payload: { showBanner: boolean; message: string }): void {
    this.showBanner = payload.showBanner;
    this.environmentName = payload.message;
  }
  @Mutation
  setMessageCount(payload: number): void {
    this.messageCount = payload;
  }
  @Mutation
  changeTitle(payload: string): void {
    this.title = payload;
  }
  @Mutation
  raiseError(payload: Array<string> | string): void {
    if (Array.isArray(payload)) {
      this.errors = payload;
    } else {
      this.errors = [payload];
    }
  }
  @Mutation
  showMessage(payload: string): void {
    this.success = payload;
  }
}
