import { RouteRecordRaw } from 'vue-router';
import { OidcAuth } from 'vue-oidc-client/vue3';
import template from '@/views/defaultTemplate.vue';
export default function useAdminRoutes(
  idsrvAuth: OidcAuth
): Array<RouteRecordRaw> {
  const routes: Array<RouteRecordRaw> = [
    {
      path: '/admin',
      name: 'admin',
      redirect: '/admin/users',
      component: template,
      meta: {
        resource: ['User', 'Company'],
        authName: idsrvAuth.authName
      },
      children: [
        {
          path: 'users',
          name: 'usersmain',
          redirect: 'users',
          component: template,
          children: [
            {
              path: '',
              name: 'users',
              component: () =>
                import(
                  /* webpackChunkName: "admin" */ '@/views/Administration/users.vue'
                ),
              meta: {
                resource: 'User',
                authName: idsrvAuth.authName,
                title: 'Brugere'
              }
            },
            {
              path: ':id',
              name: 'userDetails',
              component: () =>
                import(
                  /* webpackChunkName: "admin" */ '@/views/Administration/user.vue'
                ),
              meta: {
                resource: 'User',
                authName: idsrvAuth.authName,
                title: 'Bruger detaljer'
              }
            },
            {
              path: 'old',
              name: 'oldusers',
              component: () =>
                import(
                  /* webpackChunkName: "admin" */ '@/views/Administration/oldUsers.vue'
                ),
              meta: {
                resource: 'User',
                authName: idsrvAuth.authName,
                title: 'Gamle brugere'
              }
            }
          ]
        },
        {
          path: 'customers',
          name: 'customers',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '@/views/Administration/customers.vue'
            ),
          meta: {
            resource: 'Company',
            authName: idsrvAuth.authName,
            title: 'Kunder'
          }
        },
        {
          path: 'customer/:id',
          name: 'admin_customer',
          component: () =>
            import(
              /* webpackChunkName: "admin" */ '@/views/Administration/customer.vue'
            ),
          meta: {
            resource: 'Company',
            authName: idsrvAuth.authName,
            title: 'Rediger kunder'
          }
        }
      ]
    }
  ];
  return routes;
}
